.App {
  font-family: sans-serif;
  text-align: center;
}
.myStyle{
  width: 650px;
  margin-top: 50px;
}
@font-face {
  font-family: "Hacen Liner Print-out" ;
  src: url(./components/fonts/HacenLinerPrint-out.ttf);
}
@font-face {
  font-family: "Hacen Liner Screen Bd" ;
  src: url(./components/fonts/HacenLinerScreenBd.ttf);
}
@font-face {
  font-family: "Hacen Liner Screen" ;
  src: url(./components/fonts/HacenLinerScreen.ttf);
}
@font-face {
  font-family: "Hacen Liner XL" ;
  src: url(./components/fonts/HacenLinerXL.ttf);
}
@font-face {
  font-family: "Hacen Liner XXL" ;
  src: url(./components/fonts/HacenLinerXXL.ttf);
}

.font{
  font-family: 'Cairo', sans-serif;
  /*font-family: "Hacen Liner Screen" ;*/
}

/* gallery viwer  */
.pswp-thumbnail {
  display: inline-block;
  
  margin: 5px 5px 5px 5px;
  cursor: pointer;
  
}
.pswp-thumbnails {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  
}
/*-------end gallery viewer-----------*/

.container-box{
  display: flex;
  flex-direction: row;
height: auto;
font-family: "Hacen Liner Screen Bd"
;
}
.right-box{
  width: 100%;
 margin-left:50px;
}
.left-box{
  width:500px;
 }
 .listItemText   {
   font-family:"Hacen Liner XL" ;
   font-size: 20px;
 }

 .headerTopSM{
   display:none !important
 }
 .headerTopLG{
  display:flex !important
 }
 .pubText{
   font-size: 18px;
   font-weight: bolder;
 }
 .ribText{
   font-size: 18px;
   font-weight: bold;
   display:flex;
   
 }
 .footerText{
  font-size: 16px;
  display:flex;
  flex-direction: row-reverse;
  
}
.contactText{
  font-size: 20px;
 }

 @media screen and (max-width :470px){
  .ribText{
    font-size: 12px;
    font-weight: bold;
    display:flex;
    flex-direction:column;
    align-items: center;
    
  }
  
  .footerText{
    font-size: 14px;
    display:flex;
    flex-direction:column;
    align-items: center;
    
  }
  .listItemText {
    font-family:"Hacen Liner XL" ;
    font-size: 14px;
  }
  .contactText{
    font-size: 15px;
   }
 }
 

@media screen and (max-width :768px){
  .contactText{
    font-size: 18px;
   }
  .headerTopSM{
    display:flex !important
  }
  .listItemText {
    font-family:"Hacen Liner XL" ;
    font-size: 17px;
  }
  .headerTopLG{
    display:none !important
   }
  .right-box{
    width: 100%;
    margin-left:0px;
  }
  .left-box{
    width: 100%;
    
  }
  .container-box{
    display: flex;
    flex-direction: column-reverse;
  }
  .container-boxImages{
    display: flex;
    flex-direction: column-reverse;
  }
}

.zoom {
	-webkit-transform: scale(1);
	transform: scale(1);
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}
.zoom:hover {
	-webkit-transform: scale(1.3);
	transform: scale(1.3);
}
